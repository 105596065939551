var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import Features from "@pm-assets/js/common/feature-flags";
import { hasPerm } from "@pm-assets/js/common/utils/permission-utils";
import { Perms } from "@pm-assets/js/constants";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
import { decrementXHRCount, incrementXHRCount } from "@pm-assets/js/utils/xhr-counter";
function getClient() {
  return window.analytics;
}
export function page() {
  const client = getClient();
  if (client) {
    client.page();
  }
}
export function track(event, callback) {
  var _b;
  const client = getClient();
  if (client) {
    const _a = event, { name } = _a, props = __objRest(_a, ["name"]);
    incrementXHRCount();
    props.email = AuthUtils.getUserEmail();
    props.mid = AuthUtils.getActiveOrg() || "NA";
    props.is_legacy = Features.isLegacyCustomer();
    props.can_manage_subscription = hasPerm(Perms.CAN_MANAGE_SUBSCRIPTION);
    props.management_name = AuthUtils.getManagementName();
    const defaultOptions = {
      integrations: {
        All: true
      }
    };
    if (window.PM.user.orgType !== "m" && ((_b = window.Appcues) == null ? void 0 : _b.track)) {
      window.Appcues.track(name, props);
    }
    if (window.PM.job_title) {
      props.job_title = window.PM.job_title;
    }
    client.track(name, props, defaultOptions, () => {
      decrementXHRCount();
      if (callback) {
        callback();
      }
    });
  }
}
export const ActivitiesFiltered = (meld_id, filters) => {
  return { name: "Activities Filtered", meld_id, filters };
};
export const AssignOpened = (meld_id) => {
  return { name: "Assign Opened", meld_id };
};
export const FinishMeldOpened = (meld_id) => {
  return { name: "Finish Meld Opened", meld_id };
};
export const EditWorkEntryOpened = (meld_id) => {
  return { name: "Edit Work Entry Opened", meld_id };
};
export const NewWorkEntryOpened = (meld_id) => {
  return { name: "New Work Entry Opened", meld_id };
};
export const AssignRecommended = (index, meld_id) => {
  return { meld_id, name: "Recommendation Assigned", index };
};
export const RecommendSelected = (user_id) => {
  return { name: "Selected Recommendations", user_id };
};
export const ScheduleRecommended = (meld_id) => {
  return { name: "Recommendation Scheduled", meld_id };
};
export const TrackPWAInstall = () => {
  return {
    name: "PWA install",
    email: AuthUtils.getUserEmail(),
    vendor_id: AuthUtils.getActiveOrg()
  };
};
export const OwnerChatSent = ({ pageName, ownerId, meldId }) => {
  return { name: "Owner Chat Sent", owner_id: ownerId, meld_id: meldId, page_name: pageName };
};
export const OwnerMarkMeldAsRead = ({ pageName, ownerId, meldId }) => {
  return { name: "Owner Mark Meld As Read", owner_id: ownerId, meld_id: meldId, page_name: pageName };
};
export const OwnerApprovalApproved = ({
  pageName,
  ownerId,
  meldId,
  ownerComment,
  approvalAmount
}) => {
  return {
    name: "Owner Approval Approved",
    owner_id: ownerId,
    meld_id: meldId,
    page_name: pageName,
    owner_comment: ownerComment,
    approval_amount: approvalAmount
  };
};
export const OwnerApprovalRejected = ({
  pageName,
  ownerId,
  meldId,
  ownerComment,
  approvalAmount
}) => {
  return {
    name: "Owner Approval Rejected",
    owner_id: ownerId,
    meld_id: meldId,
    page_name: pageName,
    owner_comment: ownerComment,
    approval_amount: approvalAmount
  };
};
export const OwnerFilesDownloaded = ({
  pageName,
  ownerId,
  meldId,
  meldFiles,
  tenantMeldFiles,
  vendorMeldFiles
}) => {
  return {
    name: "Owner Files Downloaded",
    owner_id: ownerId,
    meld_id: meldId,
    page_name: pageName,
    meld_files: meldFiles,
    tenant_meld_files: tenantMeldFiles,
    vendor_meld_files: vendorMeldFiles
  };
};
export const OwnerHubMeldDetailOpened = ({ ownerId, meldId, pageName }) => {
  return { name: "Owner Hub Meld Detail Opened", owner_id: ownerId, meld_id: meldId, page_name: pageName };
};
export const VendorMeldDetailsOpened = ({ meldId, pageName }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Details Opened",
    meld_id: meldId,
    page_name: pageName
  };
};
export const VendorFiltersApplied = ({ meldId, pageName }) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Filters Applied", meld_id: meldId, page_name: pageName };
};
export const VendorFiltersCleared = ({ pageName }) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Filters Cleared", page_name: pageName };
};
export const VendorSortByOpened = ({ meldId, pageName }) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Sort By Opened", meld_id: meldId, page_name: pageName };
};
export const VendorContactsOpened = ({ meldId, pageName }) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Contacts Opened", meld_id: meldId, page_name: pageName };
};
export const VendorSchedulingCalendarOpened = ({ meldId, pageName }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Scheduling Calendar Opened",
    meld_id: meldId,
    page_name: pageName
  };
};
export const VendorChatOpened = ({ meldId, pageName }) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Chat Opened", meld_id: meldId, page_name: pageName };
};
export const VendorInvoiceDraftSaved = ({ meldId, pageName }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Draft Saved",
    meld_id: meldId,
    page_name: pageName
  };
};
export const VendorInvoiceSubmitted = ({ meldId, pageName }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Submitted",
    meld_id: meldId,
    page_name: pageName
  };
};
export const VendorInvoiceCanceled = ({ meldId, pageName }) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Invoice Canceled", meld_id: meldId, page_name: pageName };
};
export const VendorEditInvoiceOpened = ({ meldId, pageName }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Edit Invoice Opened",
    meld_id: meldId,
    page_name: pageName
  };
};
export const VendorMeldAccepted = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Accepted",
    meld_id: meldId,
    page_name: "vendor_hub>incoming_requests"
  };
};
export const VendorMeldRejected = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Rejected",
    meld_id: meldId,
    page_name: "vendor_hub>incoming_requests"
  };
};
export const VendorInProgressFilterApplied = () => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor In Progress Filter Applied",
    page_name: "vendor_hub>melds_list"
  };
};
export const VendorInactiveFilterApplied = () => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Inactive Filter Applied",
    page_name: "vendor_hub>melds_list"
  };
};
export const VendorDraftsFilterApplied = () => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Drafts Filter Applied",
    page_name: "vendor_hub>invoices"
  };
};
export const VendorSubmittedFilterApplied = () => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Submitted Filter Applied",
    page_name: "vendor_hub>invoices"
  };
};
export const VendorClosedFilterApplied = () => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Closed Filter Applied",
    page_name: "vendor_hub>invoices"
  };
};
export const VendorMeldInformationOpened = () => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Information Opened",
    page_name: "vendor_hub>meld_details"
  };
};
export const VendorDirectionsOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Directions Opened",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>information"
  };
};
export const VendorFilesUploadedSuccess = ({ meldId, meldFiles }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Files Uploaded Success",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>information",
    meld_files: meldFiles
  };
};
export const VendorFilesUploadedFailed = ({ meldId, meldFiles }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Files Uploaded Failed",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>information",
    meld_files: meldFiles
  };
};
export const VendorMeldFinished = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Finished",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details"
  };
};
export const VendorMeldPrinted = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Printed",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details"
  };
};
export const VendorChatSent = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Vendor Chat Sent",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>chat"
  };
};
export const VendorChatReceiverSelected = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Chat Receiver Selected",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>chat"
  };
};
export const VendorAppointmentWindowChanged = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Appointment Window Changed",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability"
  };
};
export const VendorAvailabilitySubmitted = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Availability Submitted",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability"
  };
};
export const VendorChangesCleared = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Changes Cleared",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability"
  };
};
export const VendorAvailabilityDeferred = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Availability Deferred",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability"
  };
};
export const VendorMeldScheduled = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Scheduled",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>instant_schedule"
  };
};
export const VendorSchedulingDeferred = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Scheduling Deferred",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>instant_schedule"
  };
};
export const VendorInvoiceCreationOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Creation Opened",
    meld_id: meldId,
    page_name: "vendor_hub>melds_list>create_invoice"
  };
};
export const VendorCreateInvoiceOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Create Invoice Opened",
    meld_id: meldId,
    page_name: "vendor_hub>invoice_creation"
  };
};
export const VendorUploadInvoiceOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Upload Invoice Opened",
    meld_id: meldId,
    page_name: "vendor_hub>invoice_creation"
  };
};
export const VendorImportQuickBooksOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks opened",
    meld_id: meldId,
    page_name: "vendor_hub>import_from_QuickBooks"
  };
};
export const VendorImportQuickBooksCanceled = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Canceled",
    meld_id: meldId,
    page_name: "import_from_QuickBooks_canceled>vendor_hub"
  };
};
export const VendorImportQuickBooksSubmitted = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Submitted",
    meld_id: meldId,
    page_name: "invoice_from_QuickBooks_submitted>vendor_hub"
  };
};
export const VendorQuickBooksInvoiceSuggested = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Invoice Suggested",
    meld_id: meldId,
    page_name: "invoice_from_QuickBooks_suggested"
  };
};
export const VendorImportQuickBooksInvoiceSelected = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Invoice Selected",
    meld_id: meldId,
    page_name: "invoice_from_QuickBooks_selected"
  };
};
export const VendorInvoiceDownloaded = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Downloaded",
    meld_id: meldId,
    page_name: "vendor_hub>invoices"
  };
};
export const VendorNotificationsOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Notifications Opened",
    meld_id: meldId,
    page_name: "vendor_hub"
  };
};
export const VendorAccountSettingsOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Account Settings Opened",
    meld_id: meldId,
    page_name: "vendor_hub>global_search"
  };
};
export const VendorUserManagementOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor User Management Opened",
    meld_id: meldId,
    page_name: "vendor_hub>global_search"
  };
};
export const VendorHelpCenterOpened = ({ meldId }) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Help Center Opened",
    meld_id: meldId,
    page_name: "vendor_hub>global_search"
  };
};
export const EstimateDetailsOpened = ({ meldId, pageName }) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Estimate Details Opened", meld_id: meldId, page_name: pageName };
};
export const EstimateCreated = (meldId, vendorId) => {
  return {
    management_id: AuthUtils.getActiveOrg(),
    name: "Estimate Created",
    vendor_id: vendorId,
    meld_id: meldId,
    page_name: "meld_details>estimates>request_estimate"
  };
};
export const DownloadBillsModalOpened = () => {
  return {
    name: "Download Bills Modal Opened",
    page_name: "reporting"
  };
};
export const BillsBulkDownloaded = (startDate, endDate) => {
  return {
    name: "Bills Bulk Downloaded",
    page_name: "reporting",
    startDate,
    endDate
  };
};
export const WorkflowToggleModalOpened = (id, pg) => {
  return {
    name: "Workflow Toggled Modal Opened",
    page_name: `workflows_${pg}`,
    workflow_id: id
  };
};
export const WorkflowToggled = (id, enabled, pg, name) => {
  return {
    name: `Workflow Toggled ${enabled ? "On" : "Off"}`,
    page_name: `workflows_${pg}`,
    workflow_id: id,
    workflow_title: name
  };
};
export const WorkflowErrorOnCreate = (templateId, title) => {
  return {
    name: "Workflow Error On Save",
    page_name: "workflows_form",
    workflow_template_id: templateId,
    workflow_title: title
  };
};
export const WorkflowErrorOnUpdate = (id, title) => {
  return {
    name: "Workflow Error On Update",
    page_name: "workflows_form",
    workflow_id: id,
    workflow_title: title
  };
};
export const PCPNavigationToggled = () => {
  return {
    name: "PCP Opened",
    page_name: "management_hub > services_index"
  };
};
export const PCPNavigationLandingDisplayed = () => {
  return {
    name: "PCP Landing Displayed",
    page_name: "management_hub > services_landing"
  };
};
export const PCPLearnMoreClicked = () => {
  return {
    name: "PCP Learn More Selected",
    page_name: "management_hub > services_index"
  };
};
export const PCPCreateServiceForm = () => {
  return {
    name: "Service Creation Form Opened",
    page_name: "management_hub > services_index"
  };
};
export const PCPServiceDetailsClicked = () => {
  return {
    name: "Service Details Opened",
    page_name: "management_hub > services_index > see_details"
  };
};
export const PCPServiceCreationCanceled = () => {
  return {
    name: "Service Creation Canceled",
    page_name: "management_hub > service_creation_form"
  };
};
export const PCPServiceUpdateCanceled = () => {
  return {
    name: "Service Update Canceled",
    page_name: "management_hub > service_edit_form"
  };
};
export const PCPServiceCreatedClicked = () => {
  return {
    name: "Service Created",
    page_name: "management_hub > service_creation_form"
  };
};
export const PCPServiceUpdatedClicked = () => {
  return {
    name: "Service Updated",
    page_name: "management_hub > service_edit_form"
  };
};
export const PCPServiceDeleteModalOpened = () => {
  return {
    name: "Service Delete Modal Opened",
    page_name: "management_hub > service_details"
  };
};
export const PCPDeleteServiceClicked = () => {
  return {
    name: "Service Deleted",
    page_name: "management_hub > service_details"
  };
};
export const PCPDeleteServiceCancled = () => {
  return {
    name: "Service Deletion Canceled",
    page_name: "management_hub > service_details"
  };
};
export const PCPServiceEditFormOpened = () => {
  return {
    name: "Service Edit Form Opened",
    page_name: "management_hub > service_details"
  };
};
export const PCPOwnerDetailsClicked = () => {
  return {
    name: "Owner Details Opened from Service",
    page_name: "management_hub > service_details"
  };
};
export const PCPRemoveOwnerModalOpened = () => {
  return {
    name: "Remove Owner Modal Opened",
    page_name: "management_hub > service_details"
  };
};
export const PCPRemoveOwnerClicked = () => {
  return {
    name: "Owner Removed",
    page_name: "management_hub > service_details"
  };
};
export const PCPRemoveOwnerCanceled = () => {
  return {
    name: "Owner Removal Canceled",
    page_name: "management_hub > service_details"
  };
};
export const PCPBackToServiceIndexClicked = () => {
  return {
    name: "Services Index Opened",
    page_name: "management_hub > service_details"
  };
};
export const PCPBackToServiceDetailClicked = () => {
  return {
    name: "Service Details Opened",
    page_name: "management_hub > service_edit_form"
  };
};
export const PCPCreateServiceFromLandingClicked = () => {
  return {
    name: "PCP Create Service Selected From Landing",
    page_name: "management_hub > services_landing"
  };
};
export const PCPLearnMoreFromLandingClicked = () => {
  return {
    name: "PCP Learn More Selected From Landing",
    page_name: "management_hub > services_landing"
  };
};
export const PCPIntroMaybeLaterClicked = () => {
  return {
    name: "PCP Maybe Later Selected From Landing",
    page_name: "management_hub > services_landing"
  };
};
export const PCPPublishClicked = () => {
  return {
    name: "Service Published",
    page_name: "management_hub > propertycare+ > [service_index | service_details]"
  };
};
export const PCPPublishNotNowClicked = () => {
  return {
    name: "Publish Service Canceled",
    page_name: "management_hub > propertycare+ > [service_index | service_details]"
  };
};
export const PCPUnpublishedClicked = () => {
  return {
    name: "Service Unpublished",
    page_name: "management_hub > propertycare+ > [service_index | service_details]"
  };
};
export const PCPUnpublishedNotNowClicked = () => {
  return {
    name: "Unpublish Service Canceled",
    page_name: "management_hub > propertycare+ > [service_index | service_details]"
  };
};
export const PCPPublishedTabClicked = () => {
  return {
    name: "PCP Published Tab Selected",
    page_name: "management_hub > propertycare+ > services_index"
  };
};
export const PCPDraftsTabClicked = () => {
  return {
    name: "PCP Draft Tab Selected",
    page_name: "management_hub > propertycare+ > services_index"
  };
};
export const PCPPublishFromIndexCardClicked = () => {
  return {
    name: "Service Publish Modal Opened from Index",
    page_name: "management_hub > propertycare+ > services_index"
  };
};
export const PCPPublishFromDetailsCardClicked = () => {
  return {
    name: "Service Publish Modal Opened from Details",
    page_name: "management_hub > propertycare+ > services_details"
  };
};
export const PCPUnpublishFromIndexCardClicked = () => {
  return {
    name: "Service Unpublish Modal Opened from Index",
    page_name: "page_name: management_hub > propertycare+ > services_index"
  };
};
export const PCPUnpublishFromDetailsCardClicked = () => {
  return {
    name: "Service Unpublish Modal Opened from Details",
    page_name: "management_hub > propertycare+ > services_details"
  };
};
export const PCPServiceTemplateTabClicked = () => {
  return {
    name: "Service Templates Tab Opened",
    page_name: "management_hub > propertycare+ > services_index"
  };
};
export const PCPConfigureTemplateButtonClicked = () => {
  return {
    name: "Configure Service with template clicked",
    page_name: "management_hub > propertycare+ > services_index"
  };
};
export const PCPConfigureTemplateButtonClickedFromLanding = () => {
  return {
    name: "Service Templates Index Opened from Landing",
    page_name: "management_hub > propertycare+ > landing_page"
  };
};
export const PCPConfigureTemplateButtonClickedFromTemplateIndex = () => {
  return {
    name: "Service Template Configured from Templates Index",
    page_name: "management_hub > propertycare+ > templates_index"
  };
};
export const PCPOwnerNavigationToggled = () => {
  return {
    name: "PCP Owner-Hub Opened",
    page_name: "owner_hub > navigation"
  };
};
export const PCPOwnerNavigationLandingDisplayed = () => {
  return {
    name: "PCP Landing Displayed",
    page_name: "owner_hub > services_landing"
  };
};
export const PCPOwnerLearnMoreClicked = () => {
  return {
    name: "PCP Learn More Selected from Owner Hub index",
    page_name: "owner_hub > services_index"
  };
};
export const PCPOwnerLearnMoreFromLandingClicked = () => {
  return {
    name: "PCP Learn More Selected From Owner Hub Landing",
    page_name: "owner_hub > services_landing"
  };
};
export const PCPOwnerServiceDetailsClicked = () => {
  return {
    name: "Service Owner-Hub Details Opened",
    page_name: "owner_hub > services_index > learn_more"
  };
};
export const PCPOptinNModalOpened = () => {
  return {
    name: "Opt-in Modal Opened",
    page_name: "owner_hub > services_index"
  };
};
export const PCPOptinNModalOpenedFromDetails = () => {
  return {
    name: "Opt-in Modal Opened From Details",
    page_name: "owner_hub > service_details"
  };
};
export const PCPOptInNowClicked = () => {
  return {
    name: "Opt-in Now Selected",
    page_name: "owner_hub > service_opt-in_confirmation"
  };
};
export const PCPOptInNowCanceled = () => {
  return {
    name: "Opt-in Confirmation Canceled",
    page_name: "owner_hub > service_opt-in_confirmation"
  };
};
export const PCPOptedInSeeDetailsClicked = () => {
  return {
    name: "Opted-In Service Details",
    page_name: "owner_hub > services_index > Opted-In Services"
  };
};
export const WLOpened = () => {
  return {
    name: "WL Settings Opened",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLSubscriptionSettingsOpened = () => {
  return {
    name: "Subscription Settings Opened from WL",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLSupportContacted = () => {
  return {
    name: "WL Support Contacted",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLUpgradeLearnMore = () => {
  return {
    name: "Upgrade Learn More Opened from WL",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLLearnMore = () => {
  return {
    name: "Upgrade Learn More Opened from WL",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLBannerLearnMore = () => {
  return {
    name: "Upgrade Learn More Opened from WL",
    page_name: "management_hub > bannner"
  };
};
export const WLLogoUploaded = () => {
  return {
    name: "WL Logo Uploaded",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLOrChooseAnotherPhoto = () => {
  return {
    name: "WL Or Choose Another Photo",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLLogoSaved = () => {
  return {
    name: "WL Logo Saved",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLEnabled = () => {
  return {
    name: "White Labeling Enabled",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLEnabledConfirmed = () => {
  return {
    name: "White Labeling Enabled Confirmed",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLDisabled = () => {
  return {
    name: "White Labeling Disabled",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLDisabledConfirmed = () => {
  return {
    name: "White Labeling Disabled Confirmed",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const WLEnablementModalClosed = () => {
  return {
    name: "White Labeling Enable Disable Modal Closed",
    page_name: "management_hub > account_settings > white_labeling"
  };
};
export const PGModalOKClicked = () => {
  return {
    name: "Bulk Property Group Modal Ok selected",
    page_name: "properties > properties",
    user: window.PM.user
  };
};
export const PGModalCancelClicked = () => {
  return {
    name: "Bulk Property Group Modal Cancel selected",
    page_name: "properties > properties"
  };
};
export const PGModalRemoveAllClicked = () => {
  return {
    name: "Bulk Property Group Modal remove all property groups selected was checked",
    page_name: "properties > properties"
  };
};
export const PGModalAddSelectedClicked = () => {
  return {
    name: "Bulk Property Group Modal add selected property groups to the selected properties was checked",
    page_name: "properties > properties"
  };
};
export const PGModalRemoveSelectedClicked = () => {
  return {
    name: "Bulk Property Group Modal remove selected property groups from the selected properties was checked",
    page_name: "properties > properties"
  };
};
export const IntegrationCardClicked = (integration_name) => {
  return {
    name: "Integration Card Clicked",
    integration_name: { integration_name },
    page_name: "management_hub > integrations "
  };
};
export const IntegrationEnabledClicked = (integration_name) => {
  return {
    name: "Integration Enabled Clicked",
    integration_name: { integration_name },
    page_name: "management_hub > integrations"
  };
};
export const BuildiumSetupIntegrationClicked = () => {
  return {
    name: "Buildium Setup Integration Button Clicked",
    page_name: "management_hub > integrations > summary"
  };
};
export const BuildiumContactSupportClicked = () => {
  return {
    name: "Buildium Setup Integration Button Clicked",
    page_name: "management_hub > integrations > summary"
  };
};
export const BuildiumMaybeLaterClicked = () => {
  return {
    name: "Buildium Setup Integration Button Clicked",
    page_name: "management_hub > integrations > summary"
  };
};
export const WFUpsellBannerClosedClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Banner Closed",
    page_name: "management_hub > banner"
  };
};
export const WFUpsellBannerLearnHowClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Banner Learn How - Clicked",
    page_name: "management_hub > banner"
  };
};
export const WFUpsellBannerSetupWorkflowClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Setup Workflow Clicked",
    page_name: "management_hub > upsell modal > setup workflow"
  };
};
export const WFUpsellWorkflowContextUpgradeTodayClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Banner Learn How - Clicked",
    page_name: "management_hub > workflows > context count > upgrade today"
  };
};
export const WFUpsellWorkflowModalConfirmClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Confirm Upgrade Clicked",
    page_name: "management_hub > workflows > modal > confirm"
  };
};
export const WFUpsellWorkflowUpgradeSuccessCompleted = (userType) => {
  return {
    name: `Legacy Upgrade - Workflows - ${userType} - Upgrade Success Completed`,
    page_name: "management_hub > workflows > context count > upgrade today"
  };
};
export const WFUpsellWorkflowContextPopoverLearnMoreClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Workflow Context Popover Learn More Clicked",
    page_name: "management_hub > workflows > context count > popover > learn more"
  };
};
export const WFUpsellSettingsUpgradeTodayClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Modal - Upgrade Today Clicked",
    page_name: "management_hub > upsell modal > upgrade today"
  };
};
export const WFUpsellPopoverOpened = (location) => {
  return {
    name: "Legacy Upgrade - Workflows - Popover Opened",
    page_name: "management_hub > workflows > popover > open",
    popover_location: location
  };
};
export const WFUpsellPopoverClosed = (location) => {
  return {
    name: "Legacy Upgrade - Workflows - Popover Closed",
    page_name: "management_hub > workflows > popover > close",
    popover_location: location
  };
};
export const WFUpsellSupportContacted = () => {
  return {
    name: "Legacy Upgrade - Workflows - Support Contacted",
    page_name: "management_hub > upsell > contact us"
  };
};
export const WFUpsellRequestAccessClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Request Access",
    page_name: "management_hub > upsell > request access"
  };
};
export const WFUpsellTimeSavingsSliderUsed = () => {
  return {
    name: "Legacy Upgrade - Workflows - Time Savings Slider Used",
    page_name: "management_hub > upsell > modal"
  };
};
export const ViewChatSummaryClicked = () => {
  return {
    name: "Managemnt Viewed Chat Summary",
    page_name: "management_hub > meld > summary > messages"
  };
};
export const ChatModifyClicked = () => {
  return {
    name: "Management Modified Chat",
    page_name: "management_hub > meld > summary > messages"
  };
};
export const VendorTranslateToOtherClicked = (language) => {
  return {
    name: `Vendor Translated Chat to ${language}`,
    page_name: "vendor_hub > meld > summary > messages"
  };
};
export const VendorTranslateToEnglishClicked = () => {
  return {
    name: "Vendor Translated Chat to English",
    page_name: "vendor_hub > meld > summary > messages"
  };
};
export const TenantTranslateToOtherClicked = (language) => {
  return {
    name: `Tenant Translated Chat to ${language}`,
    page_name: "tenant_hub > meld > summary > messages"
  };
};
export const TenantTranslateToEnglishClicked = () => {
  return {
    name: "Tenant Translated Chat to English",
    page_name: "tenant_hub > meld > summary > messages"
  };
};
export const UIRedesignToggleEnabled = (pageURL) => {
  return {
    name: "UI Redesign Toggle Enabled",
    page_name: pageURL
  };
};
export const UIRedesignToggleDisabled = (pageURL) => {
  return {
    name: "UI Redesign Toggle Disabled",
    page_name: pageURL
  };
};
export const AIUpsellGetInTouchClicked = () => {
  return {
    name: "Insights Pro - Get in Touch Button Clicked",
    page_name: "management_hub > Actionable Insights > button"
  };
};
export const MergeMeldTransitionToConfirmation = () => {
  return {
    name: "Merge Meld - Button that leads to confirmation view clicked.",
    page_name: "management_hub > Meld Detail > Assign flyout > Merge Meld Button"
  };
};
export const MergeMeldActual = (destId, sourceId) => {
  return {
    name: `Merge Meld - Merge Meld button on confirmation Screen Clicked`,
    page_name: `management_hub > Meld Detail > Assign flyout > Merge Meld Button > Confirmation flyout Merge Meld Button | source:${sourceId} -> destination:${destId}`
  };
};
export const MergeMeldAlertLinkClicked = (destId) => {
  return {
    name: `Merge Meld - Merged Meld Alert link to destination clicked`,
    page_name: `management_hub > Meld Detail > Merged Meld Alert > Link to destination meld | source -> destination:${destId}`
  };
};
export const CreateUnlinkedUnitClicked = () => {
  return {
    name: "Created unlinked unit",
    page_name: "management_hub > Properties > Units > New Unit"
  };
};
export const CreateUnlinkedPropertyClicked = () => {
  return {
    name: "Created unlinked property",
    page_name: "management_hub > Properties > Properties > New Property"
  };
};
export const NearbyMeldsClicked = () => {
  return {
    name: "User viewed nearby melds",
    page_name: "management_hub > meld > summary"
  };
};
export const SearchFilterSelected = (event) => {
  return __spreadProps(__spreadValues({}, event), {
    name: "Search Filter Selected - Management App"
  });
};
export const SearchResultClicked = (event) => {
  return __spreadProps(__spreadValues({}, event), {
    name: "Search Result Clicked - Management App"
  });
};
export const SearchViewMoreClicked = (event) => {
  return __spreadProps(__spreadValues({}, event), {
    name: "Search View More Clicked"
  });
};
export const SearchPgCheckboxClicked = (event) => {
  return __spreadProps(__spreadValues({}, event), {
    name: "Search Property Group Checkbox Clicked"
  });
};
export const retryAFExportClicked = (logId) => {
  return {
    name: `Retry AF Export Clicked`,
    log_id: logId,
    page_name: "management_hub > integrations > appfolio-api > Logs > Retry Export Button"
  };
};
export const LinkVendorFromAFLogsClicked = (logId) => {
  return {
    name: `Link Vendor From AF Logs Clicked`,
    log_id: logId,
    page_name: "management_hub > integrations > appfolio-api > Logs > Link Vendor Button"
  };
};
export const fixGLFromAFLogsClicked = (logId) => {
  return {
    name: `Fix GL From AF Logs Clicked`,
    log_id: logId,
    page_name: "management_hub > integrations > appfolio-api > Logs > Fix GL Button"
  };
};
export const NexusPartnerSearchSelected = () => {
  return {
    name: "Nexus Find New Vendors Clicked",
    page_name: "management_hub > vendors > find_new_vendors"
  };
};
export const NexusPartnerDetailInspected = (nexus_id) => {
  return {
    name: "Nexus Vendor Details Clicked",
    page_name: `management_hub > vendors > find_new_vendors > learn_more_about_this_vendor > ${nexus_id}`,
    nexus_id
  };
};
export const NexusPartnerDetailServiceAreaViewed = (nexus_id, service_area) => {
  return {
    name: "Nexus Vendor Detail Service Area Clicked",
    page_name: `management_hub > vendors > find_new_vendors > learn_more_about_this_vendor > ${nexus_id} > ${service_area}`,
    nexus_id,
    service_area
  };
};
export const NexusPartnerDetailFileViewed = (nexus_id, document_type) => {
  return {
    name: "Nexus Vendor Detail Document Clicked",
    page_name: `management_hub > vendors > find_new_vendors > learn_more_about_this_vendor > ${nexus_id} > ${document_type}`,
    nexus_id,
    document_type
  };
};
export const NexusPartnerAssignRecommended = (nexus_id, recommend_type) => {
  return {
    name: "Nexus Vendor Recommended during Assign Action",
    nexus_id,
    recommend_type
  };
};
export const NexusPartnerAssigned = (nexus_id, recommend_type) => {
  return {
    name: "Nexus Vendor Assigned",
    nexus_id,
    recommend_type
  };
};
export const NexusPartnerConnectViewed = (nexus_id, recommend_type) => {
  return {
    name: "Nexus Vendor Connect Clicked",
    nexus_id,
    recommend_type
  };
};
export const AgentCreatedMultipleAppointmentsOnInitialSchedule = (number_of_appointments) => {
  return {
    name: "Agent Created Multiple Appointments On Initial Schedule",
    page_name: "management_hub > meld_summary > calendar > Schedule",
    number_of_appointments
  };
};
export const AgentCreatedAdditionalAppointment = () => {
  return {
    name: "Agent Created Additional Appointment",
    page_name: "management_hub > meld_summary > calendar > add another > schedule"
  };
};
export const AgentRescheduledAppointment = () => {
  return {
    name: "Agent Reschedulued Appointment",
    page_name: "management_hub > meld_summary > calendar > reschedulue"
  };
};
export const AgentSentAvailabilityWithRequiredAmountOfAppointments = (number_of_required_appointments) => {
  return {
    name: "Agent Sent Availability With Required Amount Of Appointments",
    page_name: "management_hub > meld_summary > calendar > offer availability to resident > offer availability",
    number_of_required_appointments
  };
};
export const VendorCreatedMultipleAppointmentsOnInitialSchedule = (number_of_appointments) => {
  return {
    name: "Vendor Created Multiple Appointments On Initial Schedule",
    page_name: "vendor_hub > meld_summary > schedule",
    number_of_appointments
  };
};
export const VendorCreatedAdditionalAppointment = () => {
  return {
    name: "Vendor Created Additional Appointment",
    page_name: "vendor_hub > meld_summary > schedule"
  };
};
export const VendorRescheduledAppointment = () => {
  return {
    name: "Vendor Reschedulued Appointment",
    page_name: "vendor_hub > meld_summary > schedule"
  };
};
export const VendorSentAvailabilityWithRequiredAmountOfAppointments = (number_of_required_appointments) => {
  return {
    name: "Vendor Sent Availability With Required Amount Of Appointments",
    page_name: "vendor_hub > meld_summary > schedule",
    number_of_required_appointments
  };
};
const getCalendarEventMetaData = (props) => {
  return {
    isMobile: props.isMobile,
    // what is the most popular view to use the calendar with?
    selectedTimeframe: props.selectedTimeframe,
    // how far in the future are people working?
    daysOffsetFromCurrentDay: props.daysOffsetFromCurrentDay,
    minMultiDayColumnWidth: props.minMultiDayColumnWidth
  };
};
export const CalendarMeldSchedule = (props) => {
  return __spreadValues({
    name: "Calendar Meld pane scheduled",
    activeRightpane: props.activeRightpane,
    initiator: props.initiator,
    isMapOpen: props.isMapOpen,
    action: props.actionType,
    appliedQuickFilter: props.appliedQuickFilter
  }, getCalendarEventMetaData(props));
};
export const CalendarMeldAssign = (props) => __spreadValues({
  name: "Calendar Meld assigned in rightpane",
  personaType: props.personaType
}, getCalendarEventMetaData(props));
export const CalendarMeldViewDetailsClicked = (props) => __spreadValues({
  name: "Calendar Meld - 'View Details' clicked in rightpane",
  meldId: props.meldId
}, getCalendarEventMetaData(props));
export const CalendarMeldMapOpened = (props) => __spreadValues({
  name: "Calendar Meld - Map opened",
  type: props.type
}, getCalendarEventMetaData(props));
export const CalendarMeldMapNearbyMeldOpened = (props) => __spreadValues({
  name: "Calendar Meld - Map - Nearby Meld opened",
  type: props.type
}, getCalendarEventMetaData(props));
export const CalendarMeldMapNearbyMeldsHidden = (props) => __spreadValues({
  name: "Calendar Meld - Map - Nearby Meld hidden"
}, getCalendarEventMetaData(props));
export const CalendarRedesignToggledOn = (isMobile) => ({
  name: "Calendar Redesign Toggled On",
  isMobile
});
export const CalendarRedesignToggledOff = (isMobile) => ({
  name: "Calendar Redesign Toggled Off",
  isMobile
});
export const CalendarRedesignPageLoaded = (isMobile, agentTitle, selectedTimeframe) => ({
  name: "Calendar Meld - New Calendar Page Opened",
  isMobile,
  agentTitle,
  selectedTimeframe
});
export const CalendarRedesignDraftModeToggled = (selectedTimeframe, newValue) => ({
  name: `Calendar Meld - Draft Mode ${newValue ? "enabled" : "disabled without saving"}`,
  selectedTimeframe
});
export const MaxLoadError = (message, status) => ({
  name: "Max Load Error",
  message,
  status
});
export const LegacyMeldTimeDuration = (durationInSeconds) => ({
  name: "Management Legacy Meld - Time Spent",
  durationInSeconds
});
export const RequestEstimate = () => ({
  name: "Request Estimate"
});
export const CancelEstimateRequest = () => ({
  name: "Cancel Estimate Request"
});
export const CreateEstimate = (maintenanceProvider) => ({
  name: "Create Estimate",
  maintenanceProvider
});
export const CloseEstimate = () => ({
  name: "Close Estimate"
});
export const ApproveEstimate = () => ({
  name: "Approve Estimate"
});
export const ViewEstimateRequest = () => ({
  name: "View Estimate Request"
});
export const ViewEstimate = () => ({
  name: "View Estimate"
});
export const RequestOwnerApproval = () => ({
  name: "Request Owner Approval"
});
export const RequestBulkEstimateOwnerApproval = () => ({
  name: "Request Bulk Estimate Owner Approval"
});
export const PreviewOwnerApprovalRequest = () => ({
  name: "Preview Owner Approval Request"
});
export const AddEstimateLineItem = () => ({
  name: "Add Estimate Line Item"
});
export const EditEstimateLineItem = (maintenanceProvider) => ({
  name: "Edit Estimate Line Item",
  maintenanceProvider
});
export const ActivityEstimate = () => ({
  name: "Activity"
});
export const EstimateChatOpen = () => ({
  name: "Chat Opened"
});
export const EstimateChat = () => ({
  name: "Chat"
});
